@import "../../global.scss";

.intro{
    background-color: white;
    display: flex;

    @include mobile{

        /*if it is a mobile (it checks if the screen is a mobile through the mobile function that checks the
        width of the screen in the global scss file), then flex the screen info vertically */
        overflow: hidden;
        flex-direction: column;
        align-items: center;
        padding-bottom: 20px;
    }

    .left{
        flex: 0.5; // left side will take up 50% of width
        overflow: hidden;

        .imgContainer{
            width: 100%; // changed from 1100px
            height: 100%; // changed from 1100px
            background-color: $themeColor;
            border-radius: 50%; // makes the sphere around image
            display: flex;

            // makes the Ishan Baliyan person image go to the bottom.i.e. the bottom of the image goes till the radius bottom of the circle
            align-items: flex-end; 
            
            justify-content: center;
            float: right;

            @include mobile{
                width: 1100px;
                height: 1100px;
                align-items: flex-start;
                overflow: hidden;
            }

            img{
                height: 90%;
                
                @include mobile{
                    height: 50%; // makes image smaller on mobile
                }
            }
        }
    }
    .right{
        flex: 0.5; // right side will take up other 50% of width
        position: relative; // since the arrow will be absolute in it's position, the parent element must be relative

        @include mobile{
            padding-top: 20px;
        }
        
        .wrapper{
            width: 100%;
            height: 100%;
            padding-left: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include mobile{
                padding-left: 0; // removes left side padding on mobile
                align-items: center;
                justify-content:start;
                padding-top: 0px;
            }

            h1{
                font-size: 7vw; // previously 100px

                // margin is 10px from top/bottom and 0 from left/right
                margin: 10px 0; // sets margin between other text

                @include mobile{
                    font-size: 30px;
                }
            }
            h2{
                font-size: 3vw; // previously 45px
                @include mobile{
                    font-size: 20px;
                }
            }
            h3{
                font-size: 2vw; // previously 40px

                @include mobile{
                    font-size: 20px;
                }

                span{
                    // inherit 30px from h3 above
                    font-size: inherit;
                    color: $themeColor;
                }

                // makes the typing cursor blink. otherwise it would be static like: "|"
                .ityped-cursor{
                    animation: blink 1s infinite;
                }

                @keyframes blink{
                    // i.e. when the time is at 50%, 0.5 seconds, do not fade the typing cursor yet. Start fading after 0.5 seconds 
                    50%{
                        opacity: 1;
                    }
                    // every time the animation reaches 100%, at 1s, make the opacity 0, i.e. invisible
                    100%{
                        opacity: 0;

                    }
                }

            }
        }
        a{
            position: absolute;
            bottom: 10px;

            @include mobile{
                bottom: 4rem;
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
                text-align: center;
                justify-content: center;
                position: absolute;
            }

            // moves the arrow to the middle 40% of the right side (can't do 50% cause of left padding at 50px)
            left: 40%;

            img{
                width: 80px;

                @include mobile{
                    width: 50px; // smaller arrow on mobile
                }

                // animate every two seconds forever
                animation: arrowBlink 2s infinite;
            }

            // changes opacity, i.e. invisible, every time you reach the 100% time at every 2 seconds
            @keyframes arrowBlink {
                100%{
                    opacity: 0;
                }
            }

        }


        
    }

}