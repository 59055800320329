@import "../../global.scss";

.experience{
    background-color: $themeColor;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; // absolute position for the left and right arrows, so parent must be relative

    @include mobile{
        padding-top: 5px;
        justify-content: baseline;
        align-items: baseline;
    }

    .arrow{

        @include mobile{
            display: none; // remove the arrows on mobile
        }
        height: 90px;
        position: absolute;
        cursor: pointer;

        &.left{
            left: 10px; // previously 150px
            transform: rotate(90deg);
        }

        &.right{
            right: 10px; // previously 150px
            transform: rotate(-90deg);
        }
    }

    .slider{
        height: 80vh; // previously 600px
        display: flex;
        position: absolute;
        left: 0;
        transition: all 1s ease-out;
        overflow: hidden;

        @include mobile{
            height: 80%; // covers full mobile screen height
            flex-direction: column; // let the info expand vertically on mobile. must scroll down to see the slides on mobile
            justify-content: center;
        }

        .container{
            width: 100vw; // contain the whole screen
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;

            .item{
                width: 80%; // previously 950px
                height: 90%;
                background-color: white;
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: center;

                @include mobile{
                    width: 90%;
                    height: 100px; // lowers height to fit all the slides on one page for mobile
                    margin: 5px 0; // margin 5px top/bottom and 0 left/right
                    overflow: hidden;
                }

                // splitting screen into 4 pieces on left and 8 pieces on right (# of flex is # of sections)
                .left{
                    flex: 8; // proportion of left to right. i.e., left side is flex of 4 and right side is 8, so 4:8 screen proportion
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;

                    @include mobile{
                        flex: 6; // readjust so that the text is bigger and the images are smaller (flex of left side is 5 and right side is 5)
                    }

                    .leftContainer{
                        padding: 1vh;
                        width: 90%;
                        height: 70%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        overflow: hidden;

                        @include mobile{
                            width: 100%;
                            padding: 10px;
                        }

                        .imgContainer{
                            width: 6vw;
                            height: 6vw;
                            border-radius: 50%;
                            background-color: rgb(155, 217, 245);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            overflow: hidden;
                            @include mobile{
                                display: none; // do not display small icons for experience on mobile
                            }

                            img{
                                width: 4vw;
                            }
                        }

                        h2{
                            font-size: 2vh; // previously 30px
                            overflow: hidden;
                            @include mobile{
                                font-size: 12px;
                            }
                        }

                        h3{
                            font-size: 1.5vw; // previously 30px
                            overflow: hidden;
                            @include mobile{
                                font-size: 12px;
                            }
                        }

                        p{
                            font-size: 1rem; // previously 20px
                            overflow: hidden;
                            white-space: pre-line;
                            @include mobile{
                                display: none;
                            }
                        }
                        span{
                            font-size: 1.5vw;
                            font-weight: 600;
                            
                            cursor: pointer;
                            color: $themeColor;
                            overflow: hidden;

                            @include mobile{
                                display: none;
                            }
                        }
                    }
                }
                .right{
                    flex: 5;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;

                    @include mobile{
                        flex: 4; // 5 flex on left and 5 flex on right, so the text is larger and images are smaller on mobile
                        padding: 5px;
                    }

                    img{
                        width: 80%; // previously 500px
                        transform: rotate(5deg);
                        overflow: hidden;
                        padding-right: 2rem;

                        @include mobile{
                            width: 110px;
                            transform: rotate(0deg);
                        }
                    }
                }

            }
        }
    }

    
}