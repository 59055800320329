@import "../../global.scss";

.contact{
    background-color: white;
    display: flex;

    @include mobile {
        flex-direction: column; // rearrange the info to a vertical format for mobile
        overflow: -moz-hidden-unscrollable;
    }

    .left{
        flex: 1;
        overflow: hidden;

        img{
            height: 80%;
        }

        @include mobile {
            overflow: hidden;
        }
    }
    .right{
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @include mobile {
            overflow: hidden;
            flex: 3;
        }

        h2{
            font-size: 50px;

            @include mobile{
                font-size: 20px;
            }
        }

        form{
            width: 80%;
            height: 80%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;

            @include mobile{
                justify-content:unset;
                margin: 10px;
                overflow: hidden;
            }

            input{
                width: 70%; // previously 600px
                height: 30px;
                font-size: 14px;

                @include mobile{
                    width: 80%;
                    height: 10%;
                    overflow: hidden;
                }
            }

            textarea{
                width: 70%; // previously 600px
                height: 200px;
                font-size: 14px;

                @include mobile{
                    width: 80%;
                    height: 20%;
                    margin: 10px;
                }
            }

            button{
                width: 250px;
                height: 50px;
                color: white;
                background-color: $themeColor;
                border: none;
                border-radius: 10px;
                font-size: 20px;
                font-weight: 500;
                cursor: pointer;

                @include mobile{
                    width: 120px;
                    height: 25px;
                    overflow: hidden;
                }

                &:focus{
                    outline: none; // removes the weird rectangle around the button 
                }
            }

            span{
                color: $themeColor;
                font-size: 20px;

                @include mobile {
                    font-size: 0.8rem;
                    padding: 10px;
                }
            }
        }
    }
}