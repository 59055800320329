@import "../../global.scss";

.projects{
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1{
        font-size: 5vw; // previously 70px

        @include mobile{
            font-size: 20px;
        }
    }

    ul{
        margin: 10px;
        padding: 0;
        list-style: none; // removes bullet point symbols
        display: flex;

        @include mobile{
            margin: 10px 0; // 10px on top/bottom, 0 on left/right, for mobile margins
            flex-wrap: wrap; // allows the categories (e.g. mobile, web, etc...) to move around easier to fit the page
            justify-content: center;
        }
    }

    .container{
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: center; // even spacing
        flex-wrap: wrap;
        overflow: hidden;

        @include mobile{
            width: 100%; // increased width proportion on mobile
        }

        .item{
            // width: 220px; suggested sizing
            // height: 150px; suggested sizing
            width:25%; // previously 400px
            height: 40%; // previously 290px
            overflow-y: hidden; // personally added this as this removed the scroll bars
            border-radius: 20px;
            border: 1px solid #f0efef; // 1px light grey solfid border for the items
            margin: 10px 20px; // 30px top/bottom and 20px left/right
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            position: relative; // parent position must be raltive to an absolute child element
            transition: all .5s ease;
            cursor: pointer;
            

            @include mobile{
                width: 30%;
                height: 80px; // previously 80px
            }

            a{
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                //object-fit: cover;
                
                h3{
                    position: absolute;
                    font-size: 1vw; // previously 20px
                }
                img{
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    overflow-y: hidden;
                    display: unset;
                    justify-content: unset;
                    align-items: unset;
                }

                &:hover{
                    background-color: $mainColor;
                    img{
                        opacity: 0.2;
                        z-index: 0;
                    }
                }
            }

        }
    }

    .arrowclass{
        a{
            position: absolute;
            bottom: 10px;

            @include mobile{
                bottom: 4rem;
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
                text-align: center;
                justify-content: center;
                position: absolute;
            }

            // moves the arrow to the middle 40% of the right side (can't do 50% cause of left padding at 50px)
            left: 47%;

            img{
                width: 80px;

                @include mobile{
                    width: 40px; // smaller arrow on mobile
                }

                // animate every two seconds forever
                animation: arrowBlink 2s infinite;
            }

            // changes opacity, i.e. invisible, every time you reach the 100% time at every 2 seconds
            @keyframes arrowBlink {
                100%{
                    opacity: 0;
                }
            }

        }
    }

    .typingwords{
        // width: 100%;
        //     height: 100%;
        padding-left: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        //     @include mobile{
        //         padding-left: 0; // removes left side padding on mobile
        //         align-items: center;
        //         justify-content:start;
        //         padding-top: 0px;
        //     }

        h3{
            font-size: 2vw; // previously 40px

            @include mobile{
                font-size: 20px;
            }

            span{
                // inherit 30px from h3 above
                font-size: inherit;
                color: $themeColor;
            }

            // makes the typing cursor blink. otherwise it would be static like: "|"
            .ityped-cursor{
                animation: blink 1s infinite;
            }

            @keyframes blink{
                // i.e. when the time is at 50%, 0.5 seconds, do not fade the typing cursor yet. Start fading after 0.5 seconds 
                50%{
                    opacity: 1;
                }
                // every time the animation reaches 100%, at 1s, make the opacity 0, i.e. invisible
                100%{
                    opacity: 0;

                }
            }

        }
    }
}
