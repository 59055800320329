@import "../../global.scss";

.interests{
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mobile{

        // removing the statement below allowed much more proper margins at the bottom of the page
        //justify-content: space-around; // helps evely space items inside screen on mobile
        overflow: hidden;
    }

    h1{
        font-size: 50px;

        @include mobile{
            font-size: 15px; // smaller size for titles
        }
    }

    .container{
        width: 90%;
        height: 90%; // removes scroll bars, as the height is more suitable
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1vw;

        @include mobile{
            flex-direction: column; // display the info in a vertical format for mobile
            height: 90%; // expand height to full page
            overflow: hidden;
            justify-content: unset;
        }

        .card{
            width: 30%; // previously 400px
            height: 75%;
            border-radius: 10px;
            box-shadow: 0px 0px 15px -8px black; // shadow around the card
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            padding: 20px;
            transition: all 1s ease; // takes a second to do the animation of making the card bigger
            margin: 30px;

            @include mobile{
                height: 28%; // previously 165px
                width: 92%;
                padding: 5px;
                overflow: hidden;
                margin: 0px;
                padding-bottom: 0;
                margin-bottom: 0;
                justify-content: unset;
            }

            &.featured{
                width: 30%; // previously 400px
                height: 75%;
                margin: 0 30px; // 0 margin from top/bottom and 30px marin from left/right between the cards for space
                
                @include mobile{

                    // make the height/width the same for the featured card as the other cards on mobile
                     height: 28%; // previously 165px
                     width: 92%;
                     margin: 0px;
                     padding: 5px;
                     padding-bottom: 0;
                     margin-bottom: 0;
                     overflow: hidden;
                }
            }

            &:hover{
                transform: scale(1.1); // make the card 1.1x bigger when hovering
            }

            .top{
                display: flex;
                align-items: center;
                justify-content: center;

                @include mobile{
                   overflow: hidden;
                }

                img{

                    &.user{
                        height: 15vw; // previously 400px
                        width: 15vw; // previously 400px
                        margin: 10px;
                        border-radius: 50%;
                        object-fit: cover;
                        

                        @include mobile{
                            height: 11vw;
                            width: 13vw;
                        }
                    }
                }
            }

            .center{
                padding: 10px;
                border-radius: 10px;
                background-color: rgb(215, 228, 235);

                @include mobile{
                    font-size: 1.8vh;
                    overflow: hidden;
                }
            }

            .bottom{
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;

                @include mobile{
                    overflow: hidden;
                }

                h3{
                    font-size: 2vw; // previously 50px

                    @include mobile{
                        font-size: 10px; // smaller size for titles
                        display:none;
                    }
                }
                h4{
                    color: grey;

                    @include mobile{
                        display: none; // don't display te bottom text
                    }
                }
            }
        }
    }
}