@import "../../global.scss";

.topbar{
    width: 100%;
    height: 70px;

    @include mobile{
        height: 60px;
    }

    overflow-y: hidden;
    background-color: white;
    color: $mainColor;
    position: fixed;
    top: 0;

    /* Z-index brings the topbar to the front when scrolling (i.e. it stays
    when you scroll down) */

    // it is at index 3 to make it above the menu bar, which is on index 2
    z-index: 3;

    // takes 1 sec to make the change slowly, like an animation
    transition: all 1s ease;

    .wrapper{

        // 10px padding from top/bottom and 30 px padding from left/right
        padding: 10px 30px;

        // Makes all the things listed in the topbar horizontal, no longer listed in vertical order
        display: flex;

        align-items: center;

        // centers the items to their respective left/right side of the page
        justify-content: space-between;

        .left{

            // show the left side
            display: flex;
            align-items: center;

            .logo{
                font-size: 40px;
                font-weight: 700;
                text-decoration: none;  // removes the underline in the link for the name
                
                // inherit the color from the topbar main color parameters above
                color: inherit;
                margin-right: 40px;

                @include mobile{
                    margin-right: 5px;  // lower margins
                    font-size: 20px; // lower size of the main Ishan Baliyan title at the top. gives more space on mobile screen.
                }

                &:hover{
                    color: $themeColor;
                }
            }

            .itemContainer{
                display: flex;
                align-items: center;
                
                margin: 5px;

                @include mobile{
                    margin-left: 0px;  // lower margins
                    margin: 0;
                }

                .githubIcon{
                    font-size: 35px;
                    margin-right: 5px;

                    @include mobile{
                        display: none; // do not display the github icon on mobile
                    }
                }

                .linkedinIcon{
                    font-size: 45px;
                    margin-right: 2px;
                    @include mobile{
                        font-size: 30px; // make the icon smaller
                    }
                }

                .resumeIcon{
                    font-size: 45px;
                    margin-right: 2px;

                    @include mobile{
                        font-size: 30px; // make the icon smaller
                    }
                    
                }

                .resumeInfo{
                    text-decoration: none; 
                    font-weight: 600;
                    color: inherit;
                }

                .social{
                    text-decoration: none; 
                    font-weight: 600;
                    color: inherit;
                    
                    .notForMobileText{
                        @include mobile{
                            display: none; // do not display the info at the top when on mobile
                        }
                    }

                    &:hover{
                        color: $themeColor;
                    }
                }

                span{
                    font-size: 15px;
                    font-weight: 500;
                }

            }
        }
    
        .right{

            .hamburger{
                width: 32px;
                height: 25px;
                display: flex; // displays the line
                flex-direction: column; // makes you see each line completely in vertical size together (no seperation)
                justify-content: space-between; // evenly adds spaces between each vertical line so you see them seperately
                cursor: pointer;

                span{
                    width: 100%;
                    height: 3px;
                    background-color: $mainColor;

                    //changes the origin to rotate the top line from left
                    transform-origin: left;

                    // takes two seconds to animate
                    transition: all 2s ease;
                }
            }
        }

    }

    &.active{

        // Inverts colours (so now the topbar is blue and text is white)
        background-color: $mainColor;
        color: white;

        .hamburger{
            span{
                // makes the first line visible in the hamburger
                &:first-child{
                    background-color: white;
                    transform: rotate(45deg);
                }

                // makes the second line visible from hamburger
                &:nth-child(2){
                    opacity: 0;
                }
                // makes the last line visible in the hamburger
                &:last-child{
                    background-color: white;
                    transform: rotate(-45deg);
                }
            }
        }

    }
}