$mainColor: #15023a;
$themeColor: rgb(20, 147, 221);

$width_mobile: 768px;
$width_computer: 1444px;

@mixin mobile {
    @media (max-width: #{$width_mobile}){
        @content
    }
}

// main chosen colour is light blue: rgb(20, 147, 221)
// Other strong variation is fresh green: rgb(55, 224, 92)
// Lastly, another variation to consider may be the color crimson