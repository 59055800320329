@import "../../global.scss";

.menu{
    width: 300px;
    height: 100vh;
    background-color: $mainColor;

    // keep the menu in a fixed position, even when scrolling
    position: fixed;

    // initial position is at the top right
    top: 0;

    // so basically it is off the screen at the very right intially (not visible)
    right: -300px;

    // bring the menu to the front so it's visible
    z-index: 2;

    // stops overdlow and completely takes up needed space
    display: flex;

    // i.e. it is flexing vertically to take up all the avaialble and allowed vertical space
    flex-direction: column;
    align-items: center;

    //evenly spreads the space between items in the area
    justify-content: center;

    // showing a slow transition to make it an animation as the menu opens
    transition: all 1s ease;

    &.active{

        // brings the menu bar into visibility, by moving it to the visible part of the screen
        right: 0;
    }

    ul{
        margin: 0;
        padding: 0;
        list-style: none; // removes the bullet point symbols from the list
        font-size: 30px;
        font-weight: 300;
        color: white;

        // makes the width of text 60% of total menu width. Helps to stop text from adjusting and becoming wider when it becomes bold.
        width: 60%;

        li{
            margin-bottom: 25px;
            a{
                // inherits the font size and colors from the ul info above. Not from the global css file
                font-size: inherit;
                color: inherit;

                text-decoration: none; // removes underline
            }

            &:hover{
                font-weight: 500;
            }

        }
    }

}